import React from 'react'
import { css } from 'linaria'

import cx from '../../utils/cx'
import breakpoints from '../../utils/tokens/breakpoints'

const map = css`
  max-width: 100%;
  display: block;
  max-height: 84px;

  @media (min-width: ${breakpoints.sm}) {
    max-height: 120px;
  }
`

const mapContainer = css`
  position: relative;
  max-width: 140px;
  ${'' /* margin: ${rhythm(1)} auto auto; */}
  display: block;
`

const locDirectionLeft = css`
  left: var(--loc-left);

  &::before {
    left: -20px;
    top: 6px;
  }
`

const locDirectionRight = css`
  right: var(--loc-right);

  &::before {
    right: -20px;
    bottom: 6px;
  }
`

const cors = {
  Wroclaw: {
    left: '41%',
    bottom: '22%'
  },
  Konin: {
    left: '47%',
    bottom: '32%'
  },
  Bilgoraj: {
    right: '24%',
    bottom: '26%'
  },
  Warsaw: {
    right: '41%',
    bottom: '53%'
  },
  Belgrade: {
    left: '59%',
    bottom: '41%'
  },
  Katowice: {
    right: '62%',
    bottom: '28%'
  },
  Fortaleza: {
    right: '32%',
    bottom: '75%'
  }
}

const maps = {
  Poland: '/img/maps/poland.png',
  Serbia: '/img/maps/serbia.png',
  Brazil: '/img/maps/brazil.png'
}

const Location = ({ country = 'Poland', city = 'Wroclaw' }) => {
  let locationStyles = cx({
    location: true,
    [locDirectionRight]: cors[city] && Boolean(cors[city].right),
    [locDirectionLeft]: cors[city] && Boolean(cors[city].left)
  })

  return (
    <div className={mapContainer}>
      <img src={maps[country]} className={map} />
      <div
        className={locationStyles}
        style={{
          '--loc-left': cors[city].left,
          '--loc-right': cors[city].right,
          '--loc-bottom': cors[city].bottom
        }}
      >
        <span>{city}</span>
        <br />
        {country}
      </div>
    </div>
  )
}

export default Location
