import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from 'linaria'
import Img from '../../components/NonStretchedImage'
import rhythm from '../../utils/rhythm'
import { transition } from '../../utils/theme'
import breakpoints from '../../utils/tokens/breakpoints'

const container = css`
  display: grid;
  row-gap: ${rhythm(1)};
  column-gap: ${rhythm(1)};
  grid-template-columns: 1fr 1fr;
  margin-top: ${rhythm(2)};

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(4, 1fr);
  }

  .gatsby-image-wrapper {
    width: 100%;
    margin-left: 0;
    border-radius: 16px;
    transition: transform ${transition.speed.l} ${transition.curve.scaleUp};

    @media (max-width: ${breakpoints.md}) {
      max-width: 100% !important;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
`

const RetreatPhotos = () => {
  const data = useStaticQuery(graphql`
    query RetreatPhotosQuery {
      image1: file(
        relativePath: { regex: "/img/about/retreats/" }
        name: { eq: "malaga-city" }
      ) {
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      image2: file(
        relativePath: { regex: "/img/about/retreats/" }
        name: { eq: "malaga-landscape" }
      ) {
        childImageSharp {
          fluid(maxWidth: 254) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      image3: file(
        relativePath: { regex: "/img/about/retreats/" }
        name: { eq: "malaga-breakfast" }
      ) {
        childImageSharp {
          fluid(maxWidth: 160) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      image4: file(
        relativePath: { regex: "/img/about/retreats/" }
        name: { eq: "malaga-pool" }
      ) {
        childImageSharp {
          fluid(maxWidth: 254) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      image5: file(
        relativePath: { regex: "/img/about/retreats/" }
        name: { eq: "malaga-pink-tree" }
      ) {
        childImageSharp {
          fluid(maxWidth: 160) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
  `)

  return (
    <div className={container}>
      <Img
        fluid={data.image1.childImageSharp.fluid}
        alt="Bejamas team in Malaga City"
        className={css`
          ${{
            [`@media (min-width: ${breakpoints.md})`]: {
              gridColumn: '2 / 4'
            }
          }}
        `}
        style={{ marginLeft: '0' }}
      />
      <Img
        fluid={data.image3.childImageSharp.fluid}
        alt="Bejamas Team have breakfast in Malaga"
        className={css`
          ${{
            [`@media (min-width: ${breakpoints.md})`]: {
              gridColumn: '4'
            }
          }}
        `}
        style={{
          marginTop: 'auto',
          marginLeft: '0'
        }}
      />
      <Img
        fluid={data.image2.childImageSharp.fluid}
        alt="Landscape of Malaga City"
        className={css`
          ${{
            [`@media (min-width: ${breakpoints.md})`]: {
              gridColumn: '1 / 3'
            },
            [`@media (min-width: ${breakpoints.lg})`]: {
              marginLeft: `${rhythm(5)} !important`,
              marginTop: `${rhythm(1)} !important`
            }
          }}
        `}
        style={{
          marginLeft: '0'
        }}
      />
      <Img
        fluid={data.image4.childImageSharp.fluid}
        alt="Bejamas Team have breakfast in Malaga"
        className={css`
          ${{
            [`@media (min-width: ${breakpoints.md})`]: {
              gridColumn: '3',
              marginTop: `-${rhythm(3)} !important`
            }
          }}
        `}
        style={{
          marginBottom: 'auto'
        }}
      />
      <Img
        fluid={data.image5.childImageSharp.fluid}
        alt="Bejamas Team have breakfast in Malaga"
        className={css`
          ${{
            display: 'none',
            [`@media (min-width: ${breakpoints.md})`]: {
              gridColumn: '2',
              display: 'block',
              marginTop: `-${rhythm(4)} !important`
            }
          }}
        `}
      />
    </div>
  )
}

export default RetreatPhotos
