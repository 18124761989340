import React from 'react'
import { css } from 'linaria'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Button from 'components/Button'
import rhythm from '../../utils/rhythm'
import breakpoints from '../../utils/tokens/breakpoints'

import Lead from 'components/TextElements/Lead'

const container = css`
  display: grid;
  align-items: center;
  margin: 0 auto;
  padding-bottom: ${rhythm(2)};
  margin-bottom: ${rhythm(2)};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  row-gap: ${rhythm(2)};

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: ${rhythm(3)};
    margin-bottom: ${rhythm(3)};
    grid-template-columns: auto 40%;
    column-gap: ${rhythm(3 / 2)};
  }

  @media (min-width: ${breakpoints.lg}) {
    padding-left: ${rhythm(4.25)};
    grid-template-columns: auto 513px;
    column-gap: ${rhythm(6)};
  }
`

const content = css`
  color: #fff;
`

const lead = css`
  color: white;
  margin-bottom: ${rhythm(2)};
`

const BecomeOneOfUs = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      image: file(
        relativePath: { regex: "/img/footer/" }
        name: { eq: "become-one-of-us@2x" }
      ) {
        childImageSharp {
          fluid(maxWidth: 513, traceSVG: { color: "#0a0116" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div className={container}>
      <div className={content}>
        <h2>Become one of us</h2>
        <Lead className={lead}>
          Do you want to join our team and work remotely from anywhere you’d
          like? We can’t wait to hear from you!
        </Lead>
        <Button to="/contact/">Contact us</Button>
      </div>
      <Img fluid={data.image.childImageSharp.fluid} />
    </div>
  )
}

export default BecomeOneOfUs
